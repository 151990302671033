*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: none;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.cta
{
    font-family: 'Times New Roman', Times, serif;
    color: white;
    font-size: 14px;
    text-shadow: violet;
    position: absolute;
    top: 90%;
    left: 90%;
    outline: none;
    
}


a:link {
  color: #ED3080;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #ED3080;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #9C83F8;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}


/*
 // backgroundBlurGlas
        .hero_card{
        backdrop-filter: blur(2em);
        -webkit-backdrop-filter: blur(2em);
}
*/